import React from 'react'
import Layout from '../components/layout'
import styled from 'styled-components'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/Array'
import { pipe } from 'fp-ts/lib/pipeable'
import { motion } from 'framer-motion'
import { animated, useSpring } from 'react-spring'

import {
  StyledLink,
  StyledSpan,
  StyledH2,
  StyledP,
} from '../styled-components/base'
import FitKidzBanner from '../images/fit-kidz-banner.jpg'
import KidsPlaying from '../images/kids-playing.jpg'

import { fuzzysearch } from '../util/fuzzy-search'
import { navigate } from 'gatsby'
import { EndPoint } from '../components/nav/header-nav'
import { useProducts } from '../hooks/useProducts'
import { Product } from './fit-kidz-4-fun-registration'
import { Transition } from '@tailwindui/react'

const HeroImage = styled.div`
  background-image: url(${FitKidzBanner});
  box-shadow: inset 0 0 0 2000px rgba(204, 99, 24, 0.25);
  padding: 2rem;
`

const DropShadowP = styled(StyledP)`
  text-shadow: 2px 2px 6px black;
`

const HeroSection = () => {
  return (
    <HeroImage className="bg-center h-auto mx-auto max-w-screen-xl px-4sm:px-6">
      <div className="text-center">
        <StyledH2 className="text-4xl tracking-tight leading-10 font-extrabold text-white sm:text-5xl sm:leading-none md:text-6xl">
          Fit Kidz <br className="sm:hidden" />
          <StyledSpan className="text-accent-400">4 Fun</StyledSpan>
        </StyledH2>
        <DropShadowP className="mt-3 max-w-md mx-auto font-semibold text-white sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Fit Kidz 4 Fun is a kids after school fitness program as well as
          summer camp. We are dedicated to improving the health and wellness of
          today&apos;s youth by teaching a fun healthy lifestyle. Our program is
          designed to help your child discover their talents and fall back in
          love with being active.
        </DropShadowP>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md shadow">
            <StyledLink
              to={EndPoint.FIT_KIDZ_4_FUN_PRICING}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-accent-400 hover:bg-accent-600 focus:outline-none focus:shadow-outline-primary"
            >
              Sign up
            </StyledLink>
          </div>
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
            <StyledLink
              to={EndPoint.PARTICIPATING_SCHOOLS}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-accent-600 bg-white hover:text-accent-500 focus:outline-none focus:shadow-outline-blue"
            >
              Check School Availability
            </StyledLink>
          </div>
        </div>
      </div>
    </HeroImage>
  )
}

const ServicesSection = () => (
  <div className="bg-gray-50 overflow-hidden">
    <div className="relative max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <svg
        className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
      >
        <defs>
          <pattern
            id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              className="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="784"
          fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
        />
      </svg>

      <div className="relative lg:grid lg:grid-cols-3 lg:col-gap-8">
        <div className="lg:col-span-1">
          <h3 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            A fun way to stay fit.
          </h3>
        </div>
        <div className="mt-10 sm:grid sm:grid-cols-2 sm:col-gap-8 sm:row-gap-10 lg:col-span-2 lg:mt-0">
          <div>
            <div className="flex shadow-lg items-center justify-center h-12 w-12 rounded-md bg-accent-400 text-white">
              <svg
                className="h-6 w-6"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path d="M21.826 9H19.74a4.26 4.26 0 00.262-1.41c0-2.326-1.818-3.776-4.024-3.573-2.681.247-4.518 3.71-4.978 4.484-.527-.863-2.261-4.238-4.981-4.494C3.909 3.808 2 5.188 2 7.589c0 3.109 4.347 7.084 9.001 11.615 1.16-1.127 2.285-2.208 3.324-3.243l.97 1.857c-1.318 1.302-2.769 2.686-4.294 4.181C4.837 15.962 0 11.797 0 7.596 0 4.302 2.462 2.07 5.674 2c2.163-.009 4.125.957 5.327 2.952 1.177-1.956 3.146-2.942 5.253-2.942C19.318 2.01 22 4.125 22 7.605c0 .464-.06.928-.174 1.395zm-11.094 4A1.999 1.999 0 017 12a2 2 0 013.732-1h1.222l1.88-2.71a.7.7 0 011.198.081l2.27 4.437.813-1.45a.7.7 0 01.611-.358H24v2h-4.513l-1.759 2.908a.699.699 0 01-1.227-.052l-2.256-4.559-.989 1.406a.701.701 0 01-.573.297h-1.951z" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-medium text-gray-900">
                Exercise
              </h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                We start each class with a fun fitness obstacle course
                challenge. We split our group into 2- 3 lines. In our obstacle
                courses your child will go through fun activities such as,
                potato sack racing, team challenges, running (cardio), push ups,
                squats (muscle gains challenges) and so much more…
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="flex shadow-lg items-center justify-center h-12 w-12 rounded-md bg-accent-400 text-white">
              <svg
                className="h-6 w-6"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path d="M14.562 21.656c-1.515-.3-3.017-1.102-4.232-2.304 5.223-2.042 9.338-5.582 11.381-9.701 1.311 5.447-2.036 10.649-7.149 12.005M2.191 10.069c1.579.57 2.568 1.441 3.468 2.496-.111 4.025 1.862 7.31 4.534 9.263-5.379-.984-9.093-6.194-8.002-11.759m3.269-5.62c2.23.772 4.523 2.499 6.129 4.611-1.464 1.144-2.977 1.95-4.172 2.484C6.29 10.172 4.968 8.93 2.769 8.158A10.025 10.025 0 015.46 4.449m10.664-1.55c-.651 1.948-1.757 3.545-3.035 4.83-1.515-1.948-3.552-3.6-5.652-4.619a9.919 9.919 0 018.687-.211m4.513 4.079c-1.404 4.508-5.887 8.644-11.619 10.724a8.981 8.981 0 01-1.339-4.093c2.876-1.199 8.14-4.087 10.203-9.682a10.042 10.042 0 012.755 3.051M24 12c0-6.623-5.376-12-12-12C5.377 0 0 5.377 0 12s5.377 12 12 12c6.538 0 12-5.318 12-12" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-medium text-gray-900">
                Sports
              </h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Soccer, Flag Football, Volleyball, Basketball, Wiffle Ball,
                Track, Mega Ball (Our own sports game), and Self Defense. We
                teach your child about a different sport each week. All of our
                sports rotate every week.
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="flex shadow-lg items-center justify-center h-12 w-12 rounded-md bg-accent-400 text-white">
              <svg
                className="h-6 w-6"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path d="M23.999 12.149a11.985 11.985 0 01-4.706 9.378A11.951 11.951 0 0112.095 24 12.005 12.005 0 010 12c3.966 1.066 7.682-1.993 6-6 4.668.655 6.859-2.389 6.077-6a12.003 12.003 0 0111.922 12.149zM8.423 8.026c-.065 3.393-2.801 5.868-6.182 6.166 1.008 4.489 5.015 7.807 9.759 7.807 5.262 0 9.576-4.072 9.97-9.229.369-4.818-2.755-9.357-7.796-10.534-.277 2.908-2.381 5.357-5.751 5.79zM13.5 17a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 0113.5 17zM8 14.147a2 2 0 11-.001 4.001A2 2 0 018 14.147zM18 12a2 2 0 11-.001 4.001A2 2 0 0118 12zm-5 0a1 1 0 110 2 1 1 0 010-2zm2.5-5a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 0115.5 7zM3 7a1 1 0 110 2 1 1 0 010-2zM1.5 3a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 011.5 3zm6-2a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 017.5 1zM4 0a1 1 0 110 2 1 1 0 010-2z" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-medium text-gray-900">
                Snacks
              </h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                We have our snack break, each week there will be a snack of the
                week. During this break our coaches will be talking about the
                health benefits of each snack.
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="flex shadow-lg items-center justify-center h-12 w-12 rounded-md bg-accent-400 text-white">
              <svg
                className="h-6 w-6"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
              >
                <path d="M9.082 2.303A2.996 2.996 0 0112 0a2.997 2.997 0 012.923 2.316c1.701.422 3.117 1.306 4.056 2.81.748 1.199 1.054 2.393 1.553 6.089 1.002.265 1.634.865 1.912 1.76.263.846.516 3.591.554 5.029.035 1.473-.395 2.64-1.881 3.131l-.01.003c-.498 3.078-3.527 2.848-4.527 2.859-1.444.009-2.692-.004-4.576-.011-1.198.004-5.325.013-5.804-.016-1.984-.158-3.03-1.228-3.297-2.829-1.144-.363-1.904-1.3-1.904-2.805 0-1.27.205-3.603.482-5.056.233-1.083.886-1.775 1.994-2.067.47-3.648.804-5.189 1.868-6.588.951-1.251 2.245-1.956 3.739-2.322M6.255 7.126c-.436 1.241-.839 4.666-.991 5.915-.331.02-.67.032-1.004.062-.688.073-.765.255-.845.727-.274 1.602-.413 3.459-.415 4.565.01.593.204.797.695.887.156.027.929.09 1.1.105.083 2.411.128 2.588 2.649 2.611 1.335.008 4.56-.011 4.56-.011 2.637.01 3.49.021 4.862.008 2.281-.022 2.273-.42 2.347-2.607.332-.029.664-.053.995-.091.836-.118.812-.542.784-1.39a31.41 31.41 0 00-.425-4.195c-.068-.341-.178-.486-.569-.57-.274-.062-.97-.085-1.252-.102-.124-1-.548-4.579-.991-5.852-.877-2.523-3.084-3.19-5.777-3.19-2.65 0-4.843.628-5.723 3.128m11.746 10.863c-.012 1.923-.901 2.937-2.888 2.998a322.1 322.1 0 01-6.217 0C7 20.926 6.042 19.823 6 18.059v-4.068h12.001v3.998zm-7-2.998h-4c0 1.036-.023 2.071.001 3.106.045 1.318.711 1.85 1.915 1.89 2.059.021 4.118.019 6.176 0 1.383-.043 1.895-.565 1.909-2.001v-2.995h-4.001v2.998a1.001 1.001 0 01-2 0v-2.998zm.446-8.196c-1.944.149-2.953.773-3.213 5.208-.062.632-.961.629-1-.019.013-.702.153-1.945.351-2.804.359-1.542 1.033-2.742 2.543-3.185.974-.286 2.781-.285 3.749 0 1.455.426 2.133 1.555 2.496 3.037.244 1 .392 2.656.366 3.016a.5.5 0 01-.993.01c-.306-3.096-.336-5.126-3.255-5.267l.676 2.335a1.195 1.195 0 11-2.337.025l.617-2.356zm.554 1.994a.598.598 0 110 1.196.598.598 0 010-1.196m1.791-6.683A1.985 1.985 0 0011.995.999c-.814.01-1.46.46-1.783 1.102a15.367 15.367 0 013.58.005" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-medium text-gray-900">
                After School Programs
              </h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Our one of a kind Sports and Fitness program is located
                currently in Florida for kids ages 5-12. We&apos;re in community
                centers and over 23 OCPS schools in Orlando.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const SchoolDetailsModal = ({
  onClose,
  isModalVisible,
  schoolDetails,
}: {
  onClose: () => void
  isModalVisible: boolean
  schoolDetails: O.Option<Product>
}) => {
  const modalRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (isModalVisible) {
      modalRef.current?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [isModalVisible])

  return (
    <Transition
      show={isModalVisible}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div
        ref={modalRef}
        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
          </div>
          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <div
            className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg className="h-6 w-6" fill="#989898">
                      <path d="M7.902 14C6.18 12.61 5 10.032 5 7.963c0-3.094 2.158-4.89 4.187-4.961.841-.013 1.729.199 2.394.57A5.723 5.723 0 0010.237.614L11.604 0c.283.407.572 1.129.761 1.979.383-.695.848-1.262 1.475-1.628.669-.391 1.778-.412 2.518-.272-.187.658-.577 1.513-1.491 2.075-.562.345-1.467.522-2.384.453.042.283.073.574.087.867.682-.364 1.44-.484 2.243-.472C16.842 3.073 19 4.869 19 7.963c0 2.069-1.18 4.647-2.902 6.037H23v2H3.5a.5.5 0 000 1H23v2H4.5a1.5 1.5 0 000 3H23v2H4.5a3.501 3.501 0 01-2.727-5.693A2.5 2.5 0 013.5 14h4.402zM23 21H5v-1h18v1zM12.014 6c-.663-.552-1.435-1.066-2.836-.996-1 .07-2.264 1.102-2.174 3.162.072 1.682 1.25 3.751 2.473 4.504.997.626 1.711.269 2.523-.214l.038.023c.796.471 1.504.807 2.485.191 1.223-.753 2.401-2.822 2.473-4.504.09-2.06-1.174-3.092-2.174-3.162-1.226-.062-2.02.43-2.808.996zm1.75.548c1.172 1.323.973 2.565-.16 4.907 1.07-.441 2.009-1.907 2-2.933-.011-1.246-.887-2.03-1.84-1.974z" />
                    </svg>
                  </div>
                  {pipe(
                    schoolDetails,
                    O.fold(
                      () => null,
                      s => (
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            {s.productName}
                          </h3>
                          <div className="mt-2">
                            <div className="flex justify-center md:justify-start">
                              <div className="flex flex-col">
                                <span className="text-sm leading-5 text-gray-500">
                                  Week Days:
                                </span>
                                {pipe(
                                  O.fromNullable(s.productDescription),
                                  O.fold(
                                    () => null,
                                    _ => (
                                      <span className="text-sm leading-5 text-gray-500">
                                        Description:
                                      </span>
                                    )
                                  )
                                )}
                              </div>
                              <div className="ml-3">
                                <div className="flex flex-col">
                                  <span className="text-sm leading-5 text-gray-500">
                                    {s.weekDaysAvailable}
                                  </span>
                                  {pipe(
                                    O.fromNullable(s.productDescription),
                                    O.fold(
                                      () => null,
                                      description => (
                                        <span className="text-sm leading-5 text-gray-500">
                                          {description}
                                        </span>
                                      )
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  )}
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                  <button
                    type="button"
                    onClick={() =>
                      navigate(
                        `${EndPoint.FIT_KIDZ_4_FUN_REGISTRATION}?plan=${pipe(
                          schoolDetails,
                          O.map(s => s._id),
                          O.getOrElse(() => '')
                        )}`
                      )
                    }
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-accent-400 text-base leading-6 font-medium text-white shadow-sm hover:bg-accent-500 focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  >
                    <svg
                      className="w-4 h-4"
                      viewBox="0 0 22 22"
                      fill="currentColor"
                    >
                      <path d="M9.963 8.261c-.566-.585-.536-1.503.047-2.07l5.948-5.768c.291-.281.664-.423 1.035-.423.376 0 .75.146 1.035.44l-8.065 7.821zm-9.778 14.696c-.123.118-.185.277-.185.436 0 .333.271.607.607.607.152 0 .305-.057.423-.171l.999-.972-.845-.872-.999.972zm8.44-11.234l-3.419 3.314c-1.837 1.781-2.774 3.507-3.64 5.916l1.509 1.559c2.434-.79 4.187-1.673 6.024-3.455l3.418-3.315-3.892-4.019zm9.97-10.212l-8.806 8.54 4.436 4.579 8.806-8.538c.645-.626.969-1.458.969-2.291 0-2.784-3.373-4.261-5.405-2.29z" />
                    </svg>
                    <span className="ml-3">Register Now</span>
                  </button>
                </span>
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button
                    type="button"
                    onClick={() => onClose()}
                    className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  >
                    close pop up
                  </button>
                </span>
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  )

  // return pipe(
  //   schoolDetails,
  //   O.fold(
  //     () => null,
  //     s => (
  //       <div className="z-40 fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
  //         <div className="fixed inset-0 transition-opacity">
  //           <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
  //         </div>

  //         <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
  //           <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
  //             <div className="sm:flex sm:items-start">
  //               <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
  //                 <svg className="h-6 w-6" fill="#989898">
  //                   <path d="M7.902 14C6.18 12.61 5 10.032 5 7.963c0-3.094 2.158-4.89 4.187-4.961.841-.013 1.729.199 2.394.57A5.723 5.723 0 0010.237.614L11.604 0c.283.407.572 1.129.761 1.979.383-.695.848-1.262 1.475-1.628.669-.391 1.778-.412 2.518-.272-.187.658-.577 1.513-1.491 2.075-.562.345-1.467.522-2.384.453.042.283.073.574.087.867.682-.364 1.44-.484 2.243-.472C16.842 3.073 19 4.869 19 7.963c0 2.069-1.18 4.647-2.902 6.037H23v2H3.5a.5.5 0 000 1H23v2H4.5a1.5 1.5 0 000 3H23v2H4.5a3.501 3.501 0 01-2.727-5.693A2.5 2.5 0 013.5 14h4.402zM23 21H5v-1h18v1zM12.014 6c-.663-.552-1.435-1.066-2.836-.996-1 .07-2.264 1.102-2.174 3.162.072 1.682 1.25 3.751 2.473 4.504.997.626 1.711.269 2.523-.214l.038.023c.796.471 1.504.807 2.485.191 1.223-.753 2.401-2.822 2.473-4.504.09-2.06-1.174-3.092-2.174-3.162-1.226-.062-2.02.43-2.808.996zm1.75.548c1.172 1.323.973 2.565-.16 4.907 1.07-.441 2.009-1.907 2-2.933-.011-1.246-.887-2.03-1.84-1.974z" />
  //                 </svg>
  //               </div>
  //               <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
  //                 <h3 className="text-lg leading-6 font-medium text-gray-900">
  //                   {s.productName}
  //                 </h3>
  //                 <div className="mt-2">
  //                   <div className="flex justify-center md:justify-start">
  //                     <div className="flex flex-col">
  //                       <span className="text-sm leading-5 text-gray-500">
  //                         Week Days:
  //                       </span>
  //                       {pipe(
  //                         O.fromNullable(s.productDescription),
  //                         O.fold(
  //                           () => null,
  //                           _ => (
  //                             <span className="text-sm leading-5 text-gray-500">
  //                               Description:
  //                             </span>
  //                           )
  //                         )
  //                       )}
  //                     </div>
  //                     <div className="ml-3">
  //                       <div className="flex flex-col">
  //                         <span className="text-sm leading-5 text-gray-500">
  //                           {s.weekDaysAvailable}
  //                         </span>
  //                         {pipe(
  //                           O.fromNullable(s.productDescription),
  //                           O.fold(
  //                             () => null,
  //                             description => (
  //                               <span className="text-sm leading-5 text-gray-500">
  //                                 {description}
  //                               </span>
  //                             )
  //                           )
  //                         )}
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
  //             <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
  //               <button
  //                 type="button"
  //                 onClick={() =>
  //                   navigate(
  //                     `${EndPoint.FIT_KIDZ_4_FUN_REGISTRATION}?plan=${s._id}`
  //                   )
  //                 }
  //                 className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-accent-400 text-base leading-6 font-medium text-white shadow-sm hover:bg-accent-500 focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
  //               >
  //                 <svg
  //                   className="w-4 h-4"
  //                   viewBox="0 0 22 22"
  //                   fill="currentColor"
  //                 >
  //                   <path d="M9.963 8.261c-.566-.585-.536-1.503.047-2.07l5.948-5.768c.291-.281.664-.423 1.035-.423.376 0 .75.146 1.035.44l-8.065 7.821zm-9.778 14.696c-.123.118-.185.277-.185.436 0 .333.271.607.607.607.152 0 .305-.057.423-.171l.999-.972-.845-.872-.999.972zm8.44-11.234l-3.419 3.314c-1.837 1.781-2.774 3.507-3.64 5.916l1.509 1.559c2.434-.79 4.187-1.673 6.024-3.455l3.418-3.315-3.892-4.019zm9.97-10.212l-8.806 8.54 4.436 4.579 8.806-8.538c.645-.626.969-1.458.969-2.291 0-2.784-3.373-4.261-5.405-2.29z" />
  //                 </svg>
  //                 <span className="ml-3">Register Now</span>
  //               </button>
  //             </span>
  //             <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
  //               <button
  //                 type="button"
  //                 onClick={() => toggleVisiblity(false)}
  //                 className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
  //               >
  //                 close pop up
  //               </button>
  //             </span>
  //           </div>
  //         </div>
  //       </div>
  //     )
  //   )
  // )
}

const SchoolSpan = ({ children }: { children: React.ReactNode }) => (
  <span className="uppercase text-gray-600 text-lg font-medium outline-none">
    {children}
  </span>
)

const ParticipatingSchools = () => {
  const [searchTerm, setSearchTerm] = React.useState<O.Option<string>>(O.none)
  const [selectedSchool, setSelectedSchool] = React.useState<O.Option<Product>>(
    O.none
  )
  const [
    schoolDetailsVisibility,
    toggleSchoolDetailsVisibility,
  ] = React.useState(false)

  const { availableSchools } = useProducts()

  const schoolCollection = pipe(
    availableSchools,
    O.fold(
      () => null,
      schools =>
        O.isSome(searchTerm)
          ? schools.filter(s =>
              fuzzysearch(
                searchTerm.value.toLocaleLowerCase(),
                s.productName.toLocaleLowerCase()
              )
            )
          : schools
    )
  )

  return (
    <>
      <div className="bg-white">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div>
              <h2 className="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                Trusted by over 23 schools across central Florida
              </h2>
              <p className="mt-3 max-w-3xl text-lg leading-7 text-gray-500">
                We have made it our priority to develop a program that enables
                your child to excel. Enrolling in our program will improve your
                child&apos;s fitness level, sports agility, sports performance,
                teamwork, patience, leadership, sportsmanship and discipline.
              </p>
              <div className="mt-8 sm:flex">
                <div>
                  <label htmlFor="search-school" className="sr-only">
                    Search
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg
                        className="h-5 w-5 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <svg
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        >
                          <path d="M15.853 16.56A9.458 9.458 0 019.5 19C4.257 19 0 14.743 0 9.5S4.257 0 9.5 0 19 4.257 19 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zM9.5 1C14.191 1 18 4.809 18 9.5S14.191 18 9.5 18 1 14.191 1 9.5 4.809 1 9.5 1z" />
                        </svg>
                      </svg>
                    </div>
                    <input
                      id="search-school"
                      className="form-input block w-full pl-10 sm:text-sm sm:leading-5 shadow-md"
                      placeholder="Search for school.."
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchTerm(
                          e.target.value !== ''
                            ? O.some(e.target.value.trim())
                            : O.none
                        )
                      }
                    />
                  </div>
                  <button
                    onClick={() => navigate(EndPoint.PARTICIPATING_SCHOOLS)}
                    className="mt-2 text-center text-accent-400 bg-white rounded-md p-2 text-sm"
                  >
                    or click here to view all participating schools and
                    community centers
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
              {schoolCollection?.length ? (
                A.takeLeft(6)(schoolCollection).map(s => (
                  <motion.div
                    key={s._id}
                    onClick={() => {
                      setSelectedSchool(O.some(s))
                      toggleSchoolDetailsVisibility(!schoolDetailsVisibility)
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 cursor-pointer hover:shadow-lg"
                  >
                    <SchoolSpan>{s.productName}</SchoolSpan>
                  </motion.div>
                ))
              ) : (
                <div className="col-span-2">
                  <div className="flex justify-center text-gray-400">
                    sorry, school not found
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {schoolDetailsVisibility && (
        <SchoolDetailsModal
          isModalVisible={schoolDetailsVisibility}
          onClose={() =>
            toggleSchoolDetailsVisibility(!schoolDetailsVisibility)
          }
          schoolDetails={selectedSchool}
        />
      )}
    </>
  )
}

const CallToActionSection = () => (
  <div className="relative bg-secondary-800">
    <div className="h-56 bg-secondary-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
      <img
        className="w-full h-full object-cover"
        src={KidsPlaying}
        alt="Support team"
      />
    </div>
    <div className="relative max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
      <div className="md:ml-auto md:w-1/2 md:pl-10">
        <p className="mt-3 text-lg leading-7 text-gray-300">
          What makes Fit Kidz 4 Fun different, is that we have signature games
          that are fun and that bring out your child’s problem solving skills.
        </p>
        <h2 className="mt-2 text-white text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
          Ready to get started?
        </h2>
        <div className="text-base leading-6 font-semibold uppercase tracking-wider text-gray-300">
          Knowing your children are amongst professional trainers
        </div>
        <div className="mt-8">
          <div className="rounded-md shadow">
            <StyledLink
              to={EndPoint.FIT_KIDZ_4_FUN_REGISTRATION}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-accent-400 hover:bg-accent-500 focus:outline-none focus:shadow-outline-primary transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            >
              Register now
            </StyledLink>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export const FitKidz4Fun = ({ location }: { location: Location }) => {
  const springOpacity = useSpring({
    from: { opacity: 0, transform: 'translateX(90px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  })
  return (
    <Layout seoTitle="Fit Kidz 4 Fun">
      <animated.div style={springOpacity}>
        <HeroSection />
        <ServicesSection />
        <ParticipatingSchools />
        <CallToActionSection />
      </animated.div>
    </Layout>
  )
}

export default FitKidz4Fun
